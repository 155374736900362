<template>
  <div class="my-home">
    <BtnWhatsapp class="my-home__btn" />
    <div v-if="$locale">
      <Header />
      <!-- <transition name="fade" mode="out-in"> -->
      <router-view />
      <!-- </transition> -->
      <Footer />
    </div>
    <Preload v-show="!$loaded" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Footer from "@/components/home/Footer.vue";
import BtnWhatsapp from "@/components/inputs/BtnWhatsapp.vue";

export default {
  components: { Header, Footer, BtnWhatsapp },
};
</script>

<style lang="scss">
.my-home {
  position: relative;
  &__btn {
    position: fixed;
    margin-left: 80vw;
    margin-top: 87vh;
    z-index: 10;
  }
  @media (min-width: 425px) {
    &__btn {
      margin-left: 85vw;
    }
  }
  @media (min-width: 768px) {
    &__btn {
      margin-left: 91vw;
    }
  }
  @media (min-width: 1440px) {
    &__btn {
      margin-left: 94vw;
    }
  }
}

// .fade-leave-active,
// .fade-enter-active {
//   transition: opacity 0.4s ease-out;
// }
// .fade-enter {
//   opacity: 0.2;
// }
// .fade-leave {
//   opacity: 0.2;
// }
</style>
