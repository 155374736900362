<template>
  <footer id="footer" class="footer section-footer" v-if="$locale">
    <div class="footer-content main">
      <div class="footer-contact">
        <div class="footer-left">
          <SocialBlock :locale="$locale" />
        </div>
        <div class="footer-right">
          <div class="footer-right-item email">
            <a class="email__link" :href="`mailto:${$locale['info-email']}?Subject=Solicito%20mas%20informacion%20de%20servicios`">
              {{ $locale["info-email"] }}
            </a>
          </div>
          <!-- <div class="footer-right-item">{{ $locale["info-phone"] }}</div> -->
          <div class="footer-right-item">{{ $locale["info-address"] }}</div>
          <div class="footer-right-item">{{ $locale["info-address2"] }}</div>
          <div class="footer-right-item" v-html="`${$locale['info-copyright']} ${$global.getCurrentYear()}`"></div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import SocialBlock from "./SocialBlock.vue";

export default {
  components: { SocialBlock },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  margin-top: auto;
  background-image: url("https://onegroup.s3.amazonaws.com/website/Rectangle+16.svg");
  background-size: cover;
  background-color: #000;
  color: #fff;
  &-content {
    padding: $mpadding $mpadding;
  }
  &-contact {
    justify-content: space-between;
    align-items: center;
    margin-bottom: $mpadding/2;
  }
  &-left {
    margin-top: 10px;
    text-align: left;
  }
  &-right {
    text-align: center;
    .email {
      &__link {
        &:hover {
          border-bottom: solid 3px #fff;
          animation: blink 1s steps(38) infinite;
        }
      }
    }
    &-item {
      margin-top: $mpadding/2;
      &:last-child {
        font-size: 90%;
      }
    }
  }

  @media (min-width: $tablet_width) {
    &-contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $mpadding/2;
    }
    &-social {
      justify-content: left;
      margin-bottom: 0;
    }
    &-right {
      text-align: right;
    }
  }
}
</style>
